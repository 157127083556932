<template>
    <div class="content">
        <headerText></headerText>
        <!-- 第二部分 -->
        <div class="photo_img">
            <span>教育公益</span>
            <p class="fb">
                再佳学《最美课本》价格为：语数外三门学科每年1999元。
                针对希望做教育公益的知名公益基金、知名企业、名人、明星，
                再佳学会免费赞助小学视频课本《最美课本》作为其公益捐赠物。
            </p>
        </div>
        <!-- 第三部分 -->
        <div class="graphic">
            <div class="graphic_content clearfix">
                <div class="graphic_text fl">
                    <span class="donation fb">
                        再佳学向浙江侨联捐赠
                    </span>
                    <span class="hundred fb">
                        100万套
                    </span>
                    <span class="donation fb">
                        学习服务和内容
                    </span>
                    <p class="fs">习近平总书记关于侨务的重要论述为根本遵循。要在“政治立侨”、“事业兴侨”、“文化育侨”、“服务助侨”、“教育带侨”上有更大作为。浙江省委常委、统战部部长熊建平，中国侨联副主席、省政协副主席吴晶，省侨联党组书记、主席连小敏等领导见证，在杭州举行了北京再佳学科技的捐赠仪式，助力所有在国外的侨胞可以通过再佳学双语应景教育了解并学习中国优秀教育文化内容。首批捐赠10万套，共捐赠100万套。
                    </p>
                </div>
                <img src="../../assets/img/picture.png" alt="">
            </div>
        </div>
        <!-- 底部 -->
        <footerText></footerText>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                isShow: false
            };
        },
        mounted() {
            if (/Android|iPhone|iPod/i.test(navigator.userAgent)) {
                this.$router.push('/educationEn')
            }
        },
        methods: {
            
        }
    };
</script>

<style scoped lang="scss">
    @import "../../assets/css/education.scss";
</style>
